<template>
  <div>
    <b-card
      title="Input Groups with Buttons"
      no-body
      class="mb-0 pt-2 pl-2 pr-2"
    >
      <validation-observer
        #default="{ handleSubmit }"
        ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <b-row>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Tipo de Movimiento"
                rules="required"
              >
                <b-form-group
                  label="Tipo de Movimiento"
                  label-for="tipoMovimiento"
                >
                  <v-select
                    v-model="formData.tipoMovimiento"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="descripcion"
                    :options="selectTipoMovimiento"
                    :state="getValidationState(validationContext)"
                    @input="getSeries()"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="4"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Motivos"
                rules="required"
              >
                <b-form-group
                  label="Motivos"
                  label-for="serie"
                >
                  <v-select
                    v-model="formData.serie"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="serie"
                    :options="selectSerie"
                    :state="getValidationState(validationContext)"
                    @input="getNroComprobante()"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Tipo de Comprobante"
                rules="required"
              >
                <b-form-group
                  label="Tipo de Comprobante"
                  label-for="tipoDocumento"
                >
                  <v-select
                    v-model="formData.tipoDocumento"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="descripcion"
                    :options="selectTipoDocumento"
                    :state="getValidationState(validationContext)"
                    @input="getSeries()"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Nro Comprobante"
                rules="required"
              >
                <b-form-group
                  label="Nro Comprobante"
                  label-for="nro_comprobante"
                >
                  <b-form-input
                    v-model="formData.nro_comprobante"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Tipo Sujeto"
                rules="required"
              >
                <b-form-group
                  label="Tipo Sujeto"
                  label-for="forma_print"
                >
                  <v-select
                    v-model="formData.formaPrint"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="descripcion"
                    :options="selectFormaPrint"
                    :state="getValidationState(validationContext)"
                    @input="getNroComprobante()"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="sujeto"
                rules="required"
              >
                <b-form-group
                  label="Buscar sujeto"
                  label-for="sujeto"
                >
                  <v-select
                    v-model="formData.tipo_de_transporte"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="descripcion"
                    :options="selecTipoTransporte"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col
              cols="12"
              md="3"
              class="mb-1 mb-md-0"
            >
              <validation-provider
                #default="validationContext"
                name="Documento Sujeto"
                rules="required"
              >
                <b-form-group
                  label="Documento Sujeto"
                  label-for="razon_social"
                >
                  <b-form-input
                    v-model="formData.razon_social"
                    :state="getValidationState(validationContext)"
                  />
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="5"
              class="mb-1 mb-md-0"
            >
              <b-form-group
                label="Buscar Producto"
                label-for="producto"
              >
                <v-select
                  v-model="formData.preProducto"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="productoNombre"
                  :options="selectProducto"
                  @input="changeProducto"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <b-form-group
                label="Precio"
                label-for="precio"
              >
                <b-form-input
                  v-model="formData.prePrecio"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <b-form-group
                label="Cantidad"
                label-for="Cantidad"
              >
                <b-form-input
                  v-model="formData.prePrecio"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="2"
              class="mb-1 mb-md-0"
            >
              <b-form-group
                label="Unidad M."
                label-for="producto"
              >
                <v-select
                  v-model="formData.preUnidadMedida"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="descripcion"
                  :options="selectunidadMedida"
                  @input="changeunidadMedida"
                />
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
              md="1"
              class="mb-1 mt-2 mb-md-0"
            >
              <b-col md="6">
                <b-button
                  variant="primary"
                  class="btn-icon"
                  size="sm"
                  @click="addDetalle"
                >
                  <feather-icon icon="PlusSquareIcon" />
                </b-button>
              </b-col>
            </b-col>
          </b-row>
          <b-row>
            <b-col
              cols="12"
              md="12"
              class="mb-1 mt-2 mb-md-0"
            >
              <b-table
                ref="refUserListTable"
                class="position-relative"
                :items="items"
                responsive
                :fields="fieldsDetalle"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No se encontraron registros"
                :busy="isBusy"
              >
                <!-- Column: precio -->
                <template #cell(productoNombre)="data">
                  <b-form-input
                    v-if="!data.item.isService"
                    v-model="data.item.productoNombre"
                    :disabled="true"
                    size="sm"
                  />
                  <b-form-input
                    v-if="data.item.isService"
                    v-model="data.item.productoNombre"
                    size="sm"
                  />
                </template>
                <template #cell(inputUnidadMedida)="data">
                  <v-select
                    v-model="data.item.unidadMedida"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="descripcion"
                    :options="[data.item.unidadMedida]"
                    disabled
                    class="select-size-sm"
                  />
                </template>
                <!-- Column: precio -->
                <!-- <template #cell(inputPrecio)="data">
                  <b-form-input
                    v-model="data.item.precio"
                    size="sm"
                    type="number"
                    @input="changeCantidadPrecio(data.item,'P')"
                  />
                </template> -->
                <!-- Column: Cantidad -->
                <template #cell(inputCantidad)="data">
                  <b-form-input
                    v-model="data.item.cantidad"
                    type="number"
                    step="any"
                    size="sm"
                  />
                </template>
                <!-- Column: Subtotal -->
                <!-- <template #cell(inputSubtotal)="data">
                  <b-form-input
                    v-model="data.item.subtotal"
                    readonly
                    size="sm"
                  />
                </template> -->
                <!-- Column: Actions -->
                <template #cell(acciones)="data">
                  <b-dropdown
                    variant="link"
                    no-caret
                    :right="$store.state.appConfig.isRTL"
                  >

                    <template #button-content>
                      <feather-icon
                        icon="MoreVerticalIcon"
                        size="16"
                        class="align-middle text-body"
                      />
                    </template>
                    <b-dropdown-item
                      @click="deleteDetalle(data.item)"
                    >
                      <feather-icon icon="XSquareIcon" />
                      <span class="align-middle ml-50">Eliminar</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <b-row
            class="text-center"
            align-v="center"
          >
            <b-col
              cols="12"
              md="4"
            />
            <b-col
              cols="12"
              md="4"
            >
              <b-button
                variant="primary"
                class="btn-icon"
                type="submit"
                size="lg"
              >
                Guardar
                <feather-icon icon="SaveIcon" />
              </b-button>
            </b-col>
            <b-col
              cols="12"
              md="4"
            />
          </b-row>
        </b-form>
      </validation-observer>

    </b-card>
  </div>
</template>

<script>
import Vue from 'vue'
import {
  BCard, VBTooltip, BCol, BRow, BFormGroup, BForm, BFormInput, BButton, BTable, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import { useNotify } from '@/helpers/toast'
// import Ripple from 'vue-ripple-directive'
import store from '@/store'

export default {
  components: {
    BCard,
    // BFormDatepicker,
    BCol,
    BRow,
    BFormGroup,
    BForm,
    vSelect,
    BFormInput,
    // BInputGroup,
    // BInputGroupAppend,
    BButton,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BTable,
    BDropdown,
    BDropdownItem,
    // BFormDatepicker,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      required,
    }
  },
  setup(props, context) {
    const { notify } = useNotify(context)
    const titleForm = ref('')
    const sortBy = ref(null)
    const isBusy = ref(false)
    const isVentaRelacionado = ref(false)
    const blankData = {
      fechaVenta: null,
      vendedor: 0,
      tipoVenta: { descripcion: 'CONTADO', estado: true, tipoVentaId: 2 },
      formaPago: { descripcion: 'EFECTIVO', estado: true, formaPagoId: 1 },
      formaPrint: { descripcion: 'ALMACEN' },
      peso_unidad_medida: { descripcion: 'KGM' },
      tipoDocumento: 0,
      serie: 0,
      igv: 0,
      subTotal: 0,
      total: 0,
      nro_comprobante: '',
      razon_social: 'UNIVERSAL GAS S.R.LTDA.',
      direccion: '',
      efectivo: 0,
      vuelto: 0,
      punto_de_partida_codigo_establecimiento_sunat: '0000',
      punto_de_llegada_codigo_establecimiento_sunat: '0000',
      punto_de_partida_ubigeo: { ubigeo: '220910', ubigeoDescripcion: '220910|MORALES - SAN MARTIN - SAN MARTIN' },
      punto_de_llegada_ubigeo: { ubigeo: '220910', ubigeoDescripcion: '220910|MORALES - SAN MARTIN - SAN MARTIN' },
      punto_de_partida_direccion: 'JR. FRANCISCO PIZARRO NRO. 860 MORALES - SAN MARTIN - SAN MARTIN',
      punto_de_llegada_direccion: 'JR. FRANCISCO PIZARRO NRO. 860 MORALES - SAN MARTIN - SAN MARTIN',
      documentoCliente: '20362013802',
      transportista_documento_numero: '20542206188',
      transportista_documento_tipo: '6',
      transportista_denominacion: 'NUEVA DISTRIBUIDORA MUNDO GAS S.A.C.',
      tipoMovimiento: { descripcion: 'SALIDA' },
    }
    const presentaciones = ref([])
    const presentacionSel = ref({})
    const items = ref([])
    const fieldsDetalle = ref([
      { key: 'idDetalle', sortable: true, thClass: 'd-none', tdClass: 'd-none' },
      { key: 'productoNombre', label: 'Producto', sortable: true, thStyle: { width: '35%' } },
      { key: 'inputUnidadMedida', label: 'Unidad M.', thStyle: { width: '18%' } },
      //   { key: 'inputPrecio', label: 'Precio', thStyle: { width: '14%' } },
      { key: 'inputCantidad', label: 'Cantidad', thStyle: { width: '14%' } },
      { key: 'inputCompra', label: 'P.Compra', thStyle: { width: '14%' } },
      //   { key: 'inputSubtotal', label: 'Subtotal', thStyle: { width: '14%' } },
      { key: 'acciones', thStyle: { width: '5%' } },
    ])
    const formData = ref(JSON.parse(JSON.stringify(blankData)))
    const resetData = () => {
      formData.value = JSON.parse(JSON.stringify(blankData))
    }
    const selectVendedor = ref([])
    const selectTipoVenta = ref([])
    const selectFormaPago = ref([])
    const selectTipoDocumento = ref([
      { descripcion: 'FACTURA' },
      { descripcion: 'BOLETA' },
    ])
    const selectSerie = ref([])
    const selectunidadMedida = ref([])
    const selectUbigeo = ref([])
    const selectConductor = ref([])
    const selectPlaca = ref([])
    const isShow1 = ref(false)
    const isShow2 = ref(false)
    const isShow3 = ref(false)
    const isShow4 = ref(false)
    const isShow5 = ref(false)
    const isShow6 = ref(false)
    const isShow7 = ref(false)
    const isShow8 = ref(false)
    const isShow9 = ref(false)
    const documentoClienteRef = Vue.ref<HTMLInputElement | null>(null)
    const userProfile = ref(localStorage.getItem('userProfile'))
    const userData = ref(JSON.parse(localStorage.getItem('userData')))
    const selectFormaPrint = ref([
      { descripcion: 'CLIENTE' },
      { descripcion: 'PROVEEDOR' },
      { descripcion: 'ALMACEN' },
    ])
    const selectTipoMovimiento = ref([
      { descripcion: 'ENTRADA' },
      { descripcion: 'SALIDA' },
    ])
    const selecTipoTransporte = ref([
      { descripcion: 'TRANSPORTE PUBLICO', id: '01' },
      // { descripcion: 'TRANSPORTE PRIVADO', id: '02' },
    ])

    const selectMotivoTraslado = ref([
      { descripcion: 'VENTA', id: '01' },
      { descripcion: 'VENTA SUJETA A CONFIRMACION DEL COMPRADOR', id: '14' },
      { descripcion: 'COMPRA', id: '02' },
      { descripcion: 'TRASLADO ENTRE ESTABLECIMIENTOS DE LA MISMA EMPRESA', id: '04' },
      { descripcion: 'TRASLADO EMISOR ITINERANTE CP', id: '18' },
      { descripcion: 'IMPORTACION', id: '08' },
      { descripcion: 'EXPORTACION', id: '09' },
      { descripcion: 'OTROS', id: '13' },

    ])
    const selectPesoTipoUnidadMedida = ref([
      { descripcion: 'KGM' },
      { descripcion: 'TNE' },
    ])
    const selectProducto = ref([])
    const resetIsShow = () => {
      isShow1.value = false
      isShow2.value = false
      isShow3.value = false
      isShow4.value = false
      isShow5.value = false
      isShow6.value = false
      isShow7.value = false
      isShow8.value = false
      isShow9.value = false
    }
    const initData = () => {
      // console.log('denyss')
      resetIsShow()
      formData.value.tipo_de_transporte = { descripcion: 'TRANSPORTE PUBLICO', id: '01' }
      formData.value.motivo_de_traslado = { descripcion: 'TRASLADO EMISOR ITINERANTE CP', id: '18' }
      // formData.value.tipoDocumento = {}
    }
    const showForm = codigo => {
      if (codigo === '7') {
        isShow1.value = true
        isShow2.value = true
        isShow3.value = true
        isShow4.value = true
        isShow5.value = false
        isShow6.value = false
        isShow7.value = true
        isShow8.value = true
        isShow9.value = true
      }
    }
    const formatDateSend = date => {
      console.info('formatDateSend', date)
      let response = ''
      if (date !== null && date !== undefined && typeof (date) !== 'string') {
        response = `${date.day}-${date.month}-${date.year}`
      }
      if (typeof (date) === 'string') {
        const partDate = date.split('-')
        response = `${partDate[2]}-${partDate[1]}-${partDate[0]}`
      }
      return response
    }
    const getNroComprobante = async () => {
      await store.dispatch('comercial/GET_NRO_COMPROBANTE', { tipo_documento_id: formData.value.tipoDocumento.tipoDocumentoId, serie_id: formData.value.serie.serieId,
      })
        .then(response => {
          // Vue.swal.close()
          if (response) {
            console.log(formData.value.nro_comprobante)
            formData.value.nro_comprobante = response.nroDocumento
            console.log(formData.value.nro_comprobante)
          }
        })
    }
    const getSeries = async () => {
      resetIsShow()
      showForm(formData.value.tipoDocumento.codigo)
      isVentaRelacionado.value = formData.value.tipoDocumento.relacionVenta
      await store.dispatch('comercial/GET_SERIES', { tipo_documento_id: formData.value.tipoDocumento.tipoDocumentoId,
      })
        .then(response => {
          if (response) {
            selectSerie.value = response
            response.forEach((element, index) => {
              // console.info(element, index)
              if (index === 0) {
                formData.value.serie = element
                getNroComprobante()
              }
            })
          }
        })
    }
    const getParametros = async () => {
      // Vue.swal({
      //   title: 'Obteniendo Parametros',
      //   showCancelButton: false,
      //   showConfirmButton: false,
      //   allowOutsideClick: false,
      //   allowEscapeKey: false,
      //   onOpen: async () => {
      //     selectUbigeo.value = []
      //     Vue.swal.showLoading()
      //     await store.dispatch('comercial/VENTAS_PARAMETERS', {
      //     })
      //       .then(response => {
      //         // console.info('ubigeo', response.ubigeo)
      //         if (response) {
      //           presentaciones.value = response
      //           selectFormaPago.value = response.forma_pago
      //           selectTipoDocumento.value = []
      //           response.tipo_documento.forEach(row => {
      //             if (row.guia) {
      //               selectTipoDocumento.value.push(row)
      //               if (row.codigo === '7') {
      //                 formData.value.tipoDocumento = row
      //                 getSeries()
      //               }
      //             }
      //           })
      //           selectTipoVenta.value = response.tipo_venta
      //           selectUbigeo.value = response.ubigeo
      //           selectConductor.value = response.conductor
      //           response.flota.forEach(row => {
      //             const rw = row
      //             rw.descripcion = `${row.marca} ${row.placa}`
      //             selectPlaca.value.push(rw)
      //           })
      //         }
      //       })
      //     Vue.swal.close()
      //   },
      // })
    }
    const selectedConductor = () => {
      // console.log(formData.value.conductor)
      formData.value.conductor_documento_tipo = 7
      formData.value.conductor_documento_numero = formData.value.conductor.documento
      formData.value.conductor_nombre = formData.value.conductor.nombre
      formData.value.conductor_apellidos = `${formData.value.conductor.apellidoPaterno} ${formData.value.conductor.apellidoMaterno}`
      formData.value.conductor_numero_licencia = formData.value.conductor.licencia
    }
    const formParse = () => {
      const form = {
        sunatGuiaRemision: {},
        detalleGuiaRemision: [],
      }
      const tipoDocumentoRuc = 6
      const clienteDenominacion = 'UNIVERSAL GAS S.R.LTDA.'
      const clienteDireccion = 'JR. FRANCISCO PIZARRO NRO. 860 OTROS SAN MARTIN - SAN MARTIN - MORALES'
      const clienteNumeroDocumento = '20362013802'
      const sunatVenta = {
        clienteDenominacion: formData.value.motivo_de_traslado.id === '18' ? clienteDenominacion : formData.value.razon_social,
        clienteDireccion: formData.value.motivo_de_traslado.id === '18' ? clienteDireccion : formData.value.punto_de_llegada_direccion,
        clienteEmail: formData.value.email,
        clienteNumeroDocumento: formData.value.motivo_de_traslado.id === '18' ? clienteNumeroDocumento : formData.value.documentoCliente,
        clienteTipoDocumento: tipoDocumentoRuc,
        codigoUnico: '',
        conductorDenominacion: '',
        conductorDocumentoNumero: '',
        conductorDocumentoTipo: '',
        estado: true,
        fechaEmision: formatDateSend(formData.value.fecha_de_inicio_de_traslado),
        fechaInicioTraslado: formatDateSend(formData.value.fecha_de_inicio_de_traslado),
        formatoPdf: formData.value.formaPrint.descripcion,
        isEnvio: true,
        motivoTraslado: formData.value.motivo_de_traslado.id,
        numero: formData.value.serie.correlativo + 1,
        numeroBultos: formData.value.numero_de_bultos,
        observaciones: '',
        pesoBrutoTotal: formData.value.peso_bruto_total,
        pesoBrutoUnidadMedida: formData.value.peso_unidad_medida.descripcion,
        puntoLlegadaDireccion: formData.value.punto_de_llegada_direccion,
        puntoLlegadaUbigeo: (typeof formData.value.punto_de_llegada_ubigeo === 'string' ? '0000' : formData.value.punto_de_llegada_ubigeo.ubigeo),
        puntoPartidaDireccion: formData.value.punto_de_partida_direccion,
        puntoPartidaUbigeo: formData.value.punto_de_partida_ubigeo.ubigeo,
        serie: formData.value.serie.serie,
        tipoComprobante: formData.value.tipoDocumento.codigo,
        tipoTransporte: formData.value.tipo_de_transporte.id,
        transportistaDenominacion: formData.value.transportista_denominacion,
        transportistaDocumentoNumero: formData.value.transportista_documento_numero,
        transportistaDocumentoTipo: tipoDocumentoRuc,
        transportistaPlacaNumero: formData.value.transportista_placa_numero.placa,
      }
      form.sunatGuiaRemision = sunatVenta
      items.value.forEach(element => {
        // console.log(element)
        const row = {
          cantidad: element.cantidad,
          codigo: element.key,
          descripcion: element.productoNombre,
          sunatGuiaRemision: { guiaRemisionId: 0 },
          unidadMedida: (typeof element.unidadMedida === 'string' ? element.unidadMedida : element.unidadMedida.abreviatura),
        }
        form.detalleGuiaRemision.push(row)
      })
      // console.log(JSON.stringify(form))
      return (form)
    }
    const onSubmit = async () => {
      try {
        if (!formData.value.transportista_placa_numero) {
          // Vue.nextTick().then(() => console.log(this.$refs))
          // formData.value.transportista_placa_numero.focus()
          throw new Error('Tiene que seleccionar un vehiculo')
        }
        Vue.swal({
          title: 'Registrando la guia',
          showCancelButton: false,
          showConfirmButton: false,
          allowOutsideClick: false,
          allowEscapeKey: false,
          onOpen: async () => {
            Vue.swal.showLoading()
            const service = 'comercial/GUIA_CREATE'
            // formData.value.presentacion = presentacionSel.value
            await store.dispatch(service, formParse())
              .then(response => {
                Vue.swal.close()
                notify('Respuesta', response.message, 'success')
                items.value = []
                resetData()
                getParametros()
              })
              .catch(error => {
                Vue.swal.close()
                console.log(error)
                throw new Error(error)
              })
          },
        })
      } catch (error) {
        console.log(error)
        Vue.swal.close()
        notify('Error', error.message, 'danger')
      }
    }

    const searchVenta = async () => {
      console.log(formData.value.nro_venta)
      Vue.swal({
        title: 'Obteniendo Parametros',
        showCancelButton: false,
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        onOpen: async () => {
          Vue.swal.showLoading()
          await store
            .dispatch('comercial/VENTAS_FIND_ALL', {
              idEmpresa: 1,
              limit: 1,
              query: formData.value.nro_venta,
              page: 1,
              almacen: -1,
              sortBy: 'ventaId%7CDESC',
              filtro_venta: '-1',
            })
            .then(response => {
              console.log(response)
              const clienteDato = (response.items[0].clienteDato).split('|')
              console.log(clienteDato[2])
              // const origenJr = 'JR. FRANCISCO PIZARRO NRO. 860 ---- OTROS MORALES - SAN MARTIN - SAN MARTIN'
              // const origenUbigeo = '220910'
              formData.value.documentoCliente = `${clienteDato[2]}`
              formData.value.razon_social = `${clienteDato[0]}`
              formData.value.email = response.items[0].email
              // formData.value.punto_de_partida_ubigeo = origenUbigeo
              // formData.value.punto_de_partida_direccion = origenJr
              response.items[0].detalleVenta.forEach(ele => {
                const row = {
                  productoNombre: ele.productoDato,
                  precio: ele.precio,
                  cantidad: ele.cantidad,
                  subtotal: parseFloat(ele.precio * ele.cantidad).toFixed(2),
                  key: (items.value.length) === 0 ? 0 : (items.value.length + 1),
                  unidadMedida: ele.unidadMedidaDato,
                  isService: false }
                /* if (searchKey(row) !== '') {
                throw new Error('EL PRODUCTO YA FUE AGREDADO')
              } */
                console.log({ row })
                items.value.push(row)
              })

              Vue.swal.close()
            })
            .catch(error => {
              console.log(error)
              Vue.swal.close()
            })
        },
      })
    }
    const calcularVuelto = () => {
      const vuelto = formData.value.total - formData.value.efectivo
      formData.value.vuelto = 0
      if (vuelto <= 0) {
        formData.value.vuelto = vuelto * -1
      }
    }
    const getSearchDocument = async tDocumento => {
      // this.isBusy = true
      await store
        .dispatch('comercial/CLIENTE_SEARCH_DOCUMENTO', {
          documento: formData.value.documentoCliente,
          tipo_documento: tDocumento,
        })
        .then(response => {
          console.log(response)
          formData.value.razon_social = response.data.razonSocial
          formData.value.direccion = response.data.direccion
        })
        .catch(error => {
          console.log(error)
          notify('Error', error.message, 'danger')
        })
        // this.isBusy = false
    }
    const getSearchDocumentConductor = async tDocumento => {
      // this.isBusy = true
      await store
        .dispatch('comercial/CLIENTE_SEARCH_DOCUMENTO', {
          documento: formData.value.conductor_documento_numero,
          tipo_documento: tDocumento,
        })
        .then(response => {
          formData.value.conductor_denominacion = response.data.razonSocial
        })
        .catch(error => {
          console.log(error)
          notify('Error', error.message, 'danger')
        })
        // this.isBusy = false
    }

    const changeProducto = () => {
      // console.log(formData.value.preProducto)
      formData.value.prePrecio = (formData.value.preProducto.precioDefault).toFixed(2)
      selectunidadMedida.value = []
      // 1 es unidad y 2 es galon,kg
      if (formData.value.preProducto.presentacion.tipoProducto === 1) {
        selectunidadMedida.value = [{ descripcion: 'UNIDAD', abreviatura: 'NIU', convercion: 1 }]
        formData.value.preUnidadMedida = { descripcion: 'UNIDAD', abreviatura: 'NIU', convercion: 1 }
      }

      if (formData.value.preProducto.presentacion.tipoProducto === 2) {
        selectunidadMedida.value = [{ descripcion: 'GALONES', abreviatura: 'GAL', convercion: 2.018 }, { descripcion: 'KILOGRAMOS', abreviatura: 'KG', convercion: 3.78541 }]
        formData.value.preUnidadMedida = { descripcion: 'GALONES', abreviatura: 'GAL', convercion: 2.018 }
      }
    }
    const changeunidadMedida = () => {
      console.log('unidad')
    }
    const cleanPreData = () => {
      formData.value.preCantidad = null
      formData.value.prePrecio = null
      formData.value.preProducto = {}
    }
    const searchKey = row => {
      let seacrh = ''
      items.value.forEach((value, index) => {
        if (row.idProducto === value.idProducto) {
          seacrh = index
        }
      })
      return seacrh
    }
    const calcularSubtotal = () => {
      let subtotal = 0
      items.value.forEach(value => {
        subtotal += parseFloat(value.subtotal)
      })
      formData.value.total = parseFloat(subtotal)
      formData.value.subTotal = parseFloat(subtotal)
      calcularVuelto()
    }
    const addDetalle = () => {
      try {
        // const valPro = formData.value.preProducto === null
        // console.log({ valPro })
        // console.info(formData.value.preProducto, formData.value.preCantidad, formData.value.prePrecio)
        // VALIDAR CANTIDAD
        if (formData.value.preProducto === '' || formData.value.preProducto === 0 || formData.value.preProducto === undefined || formData.value.preProducto === null) {
          // formData.value.preCantidad.focus()
          throw new Error('TIENE QUE SELECCIONAR UN PRODUCTO')
        }
        if (formData.value.preCantidad === '' || formData.value.preCantidad === 0 || formData.value.preCantidad === undefined || formData.value.preCantidad === null) {
          // formData.value.preCantidad.focus()
          throw new Error('TIENE QUE INGRESAR LA CANTIDAD')
        }
        // VALIDAR PRECIO
        if (formData.value.prePrecio === '' || formData.value.prePrecio === 0 || formData.value.prePrecio === undefined || formData.value.prePrecio === null) {
          formData.value.prePrecio.focus()
          throw new Error('TIENE QUE INGRESAR EL PRECIO')
        }
        // VALIDAR SI EXISTE EL PRODUCTO
        const row = {
          ...formData.value.preProducto,
          precio: formData.value.prePrecio,
          cantidad: formData.value.preCantidad,
          subtotal: parseFloat(formData.value.prePrecio * formData.value.preCantidad).toFixed(2),
          key: (items.value.length) === 0 ? 0 : (items.value.length + 1),
          unidadMedida: formData.value.preUnidadMedida,
          isService: false }
        if (searchKey(row) !== '') {
          throw new Error('EL PRODUCTO YA FUE AGREDADO')
        }
        console.log({ row })
        items.value.push(row)
        formData.value.preUnidadMedida = []
        formData.value.preCantidad = null
        cleanPreData()
        formData.value.preProducto = null
        calcularSubtotal()
        notify('', 'Se agrego el detalle', 'success')
      } catch (error) {
        console.log(error)
        notify('Error', error.message, 'danger')
      }
    }
    const addDetalleLibre = () => {
      const row = {
        productoNombre: '',
        precio: parseFloat(0).toFixed(2),
        cantidad: parseFloat(0).toFixed(2),
        subtotal: parseFloat(0).toFixed(2),
        key: (items.value.length) === 0 ? 0 : (items.value.length + 1),
        unidadMedida: { descripcion: 'UNIDAD', abreviatura: 'NIU', convercion: 1 },
        isService: true }
      if (searchKey(row) !== '') {
        throw new Error('EL PRODUCTO YA FUE AGREDADO')
      }
      console.log({ row })
      items.value.push(row)
    }
    const deleteDetalle = row => {
      items.value.forEach((value, index) => {
        if (row.idProducto === value.idProducto) {
          items.value.splice(index, 1)
        }
      })
      calcularSubtotal()
    }
    const changeCantidadPrecio = (row, tipo) => {
      console.info(row, tipo, items)
      items.value.forEach((element, index) => {
        if (element.key === row.key) {
          items.value[index].subtotal = parseFloat(row.cantidad * row.precio).toFixed(2)
        }
      })
      calcularSubtotal()
      // row.subtotal = parseFloat(row.cantidad * row.precio).toFixed(2)
    }
    const handleVehicle = data => {
      if (data?.mtcPartida) {
        formData.value.numero_de_registro_mtc = data.mtcPartida
      } else {
        formData.value.numero_de_registro_mtc = ''
        notify('Error', 'El vehículo no cuenta con registro de mercancía en el MTC.', 'danger')
      }
    }
    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetData)
    initData()
    getParametros()
    return {
      documentoClienteRef,
      formData,
      onSubmit,
      refFormObserver,
      getValidationState,
      resetForm,
      titleForm,
      getParametros,
      presentaciones,
      presentacionSel,
      selectVendedor,
      selectTipoVenta,
      selectFormaPago,
      selectTipoDocumento,
      selectSerie,
      getSeries,
      getNroComprobante,
      selectFormaPrint,
      getSearchDocument,
      selectProducto,
      selectunidadMedida,
      fieldsDetalle,
      changeProducto,
      items,
      sortBy,
      isBusy,
      addDetalle,
      addDetalleLibre,
      cleanPreData,
      calcularSubtotal,
      deleteDetalle,
      changeCantidadPrecio,
      searchKey,
      formParse,
      changeunidadMedida,
      userProfile,
      userData,
      calcularVuelto,
      selectPesoTipoUnidadMedida,
      getSearchDocumentConductor,
      isVentaRelacionado,
      searchVenta,
      selecTipoTransporte,
      selectMotivoTraslado,
      selectUbigeo,
      selectConductor,
      selectedConductor,
      isShow1,
      isShow2,
      isShow3,
      isShow4,
      isShow5,
      isShow6,
      isShow7,
      isShow8,
      isShow9,
      resetIsShow,
      showForm,
      selectPlaca,
      formatDateSend,
      handleVehicle,
      selectTipoMovimiento,
    }
  },
}
</script>

  <style>

  </style>
